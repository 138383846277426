<template>
	<div class="bordered">
		<Title
			>传染病统计
			<template v-slot:right>
				<div class="radioGroup">
					<el-radio-group :value="dataType" @input="onRadioChange">
						<el-radio label="1">管理率排行</el-radio>
						<el-radio label="2">分布</el-radio>
					</el-radio-group>
				</div>
			</template>
		</Title>
		<div v-if="dataType === '1'">
			<div class="statContainer">
				<div v-for="i in statDataLevel" class="statItem">
					<span>{{ i.label }}</span>
          <countTo class="value" :startVal="0" :endVal="i.value" suffix="%" :duration="CountAnimationDuration"></countTo>
				</div>
			</div>
			<div class="barContainer">
				<div class="bar" v-for="i in barData">
					<span class="name">{{ i.name }}</span>
					<ProgressBar class="p" :percent="i.value / 100" color="#56E8F2"></ProgressBar>
					<countTo class="value" :startVal="0" :endVal="i.value" suffix="%" :duration="CountAnimationDuration"></countTo>
				</div>
			</div>
		</div>
		<el-table
			v-if="dataType === '2'"
			:height="432"
			:header-cell-style="{ padding: '0', height: '42px' }"
			:cell-style="{ padding: '0', height: '42px' }"
			row-class-name="hover-row"
			:data="tableData"
			stripe
			style="background-color: transparent; width: 94%; margin: 12px 3%"
		>
			<el-table-column align="center" class-name="first" prop="data1" label="省分" width="70"> </el-table-column>
			<el-table-column align="center" class-name="odd" label="高血压">
				<template slot-scope="scope">
					<countTo :startVal="0" :endVal="scope.row.data2" suffix="%" :duration="CountAnimationDuration"></countTo>
				</template>
			</el-table-column>
			<el-table-column align="center" class-name="even" prop="data3" label="心脏病">
				<template slot-scope="scope">
					<countTo :startVal="0" :endVal="scope.row.data3" suffix="%" :duration="CountAnimationDuration"></countTo>
				</template>
			</el-table-column>
			<el-table-column align="center" class-name="odd" prop="data4" label="肿瘤">
				<template slot-scope="scope">
					<countTo :startVal="0" :endVal="scope.row.data4" suffix="%" :duration="CountAnimationDuration"></countTo>
				</template>
			</el-table-column>
			<el-table-column align="center" class-name="even" prop="data5" label="精神疾病">
				<template slot-scope="scope">
					<countTo :startVal="0" :endVal="scope.row.data5" suffix="%" :duration="CountAnimationDuration"></countTo>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
import ProgressBar from '../components/ProgressBar.vue';
import BaseEchart from '@/components/BaseEchart';
import MockChinaData from '@/utils/MockChinaData';
import { random } from '@/utils';
import Title from '../components/Title';
import { barChart } from '../common';
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
import { mapState } from 'vuex';
import { randomByLevel } from '@/views/homePage/common';
export default {
	components: { Title, BaseEchart, countTo, ProgressBar },
	data() {
		return {
			CountAnimationDuration,
			option: undefined,
			dataType: '1',
			statData: [
				{ label: '慢性病病人规范管理率', value: 58 },
				{ label: '高血压患者管理率', value: 62 },
				{ label: '高血压患者规范管理率', value: 31 },
				{ label: '管理人群血压控制率', value: 58 },
				{ label: '糖尿病患者管理率', value: 72 },
				{ label: '糖尿病患规范健康管理率', value: 58 },
				{ label: '管理人群血糖控制率', value: 58 },
			],
			barData: [],
			tableData: [],
		};
	},
	mounted() {
		this.init();
	},
	computed:{
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
		statDataLevel(){
			return this.statData.map((item)=>{
				return {
					...item,
					value:item.value - (this.mapLevel + 1)
				}
			})
		}
	},
	watch: {
    mapDataArr: {
      handler() {
        this.barData = this.mapDataArr.map((d) => {
          return { name: d.regionName, value: randomByLevel({level: this.mapLevel}) };
        })
        this.option = barChart({ xData: this.barData.map((d) => d.name), yData: this.barData.map((d) => d.value) });
		this.tableData = this.mapDataArr.map((k) => {
				return { data1: k.regionName, data2: random(0, 100), data3: random(0, 100), data4: random(0, 100), data5: random(0, 100) };
			});
	}
    }
  },
	methods: {
		onRadioChange(e) {
			this.dataType = e;
		},
		init() {
			const dataX = Object.keys(MockChinaData).map((k) => {
				return { name: k, value: random(0, 100) };
			});
			this.tableData = Object.keys(MockChinaData).map((k) => {
				return { data1: k, data2: random(0, 100), data3: random(0, 100), data4: random(0, 100), data5: random(0, 100) };
			});
			this.barData = Object.keys(MockChinaData).map((k) => {
				return { name: k, value: random(0, 100) };
			});
			this.option = barChart({ xData: this.barData.map((d) => d.name), yData: this.barData.map((d) => d.value) });
		},
	},
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';
// .bordered{
//   background: rgba(5, 41, 92, .6);
//   border: 2px solid rgba(17, 94, 205, .2);
// }
/deep/ .el-table {
	background-color: transparent !important;
}

/deep/ .el-table,
.el-table__expanded-cell {
	background-color: transparent; //这是设置透明背景色
}

/deep/ .el-table__header-wrapper {
	tr {
		background-color: transparent;
	}
	.odd {
		font-size: 14px;
	}
	.even {
		font-size: 14px;
	}

	.el-table__cell {
		color: white;
		background-color: #153b6b;
		border-bottom-color: #081735;
	}
}

/deep/ .el-table__body-wrapper {
	.el-table__row {
		background-color: #0d2e5a !important;
	}

	.el-table__row--striped {
		.el-table__cell {
			background-color: #153b6b !important;
		}
	}
}

/deep/ .hover-row > td {
	background-color: initial !important;
}

/deep/ .first {
	div {
		color: rgba(255, 255, 255, 0.8);
	}
}

/deep/ .odd {
	color: #1fbdff;
	font-size: 18px;
}

/deep/ .even {
	color: #00f0ff;
	font-size: 18px;
}
/deep/ .el-table td.el-table__cell, 
/deep/ .el-table th.el-table__cell.is-leaf{
	border-bottom: 2px solid rgba(5, 41, 92, .6) !important;
}

.statContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	padding: 12px;
	background-color: #112c58;
	margin: 12px 12px 0;
	.statItem {
		width: calc(50% - 8px);
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		align-items: center;
		.value {
			display: inline-block;
			margin-left: 12px;
			font-size: 16px;
			color: $main-text;
		}
	}
}
.barContainer {
	display: flex;
	flex-direction: column;
	padding: 12px;
	overflow-y: auto;
	height: 278px;
	.bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
		margin-bottom: 24px;
		.name {
			width: 42px;
			flex-shrink: 0;
			text-align: right;
		}
		.value {
			width: 30px;
			flex-shrink: 0;
			text-align: right;
		}
		.p {
			flex: 1;
			width: unset !important;
		}
	}
}

.stat {
	margin: 16px 12px;
	padding: 0 16px;
	height: 53px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #0e2e62;
	.name {
		font-size: 14px;
		color: white;
	}
	.value {
		color: $main-text;
		font-size: 26px;
	}
}

.boardContainer {
	display: flex;
	gap: 12px;
	padding: 0 12px;
	.bar {
		flex: 1;
		.label {
			margin-bottom: 4px;
		}
		.suffix {
			margin-left: 6px;
		}
	}
}
</style>
