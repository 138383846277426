<template lang="">
  <el-row class="page" style="margin: 0" :gutter="16">
    <el-col :span="6">
			<div class="m_xhealth">
				<Title>健康档案</Title>
				<TotalAnalysis style="marginBottom: 0" :value="valueByLevel"></TotalAnalysis>
				<BaseEchart :option="option" style="width: 100%; height: 288px"></BaseEchart>
			</div>
			<LeftCenter></LeftCenter>
			<LeftBottom></LeftBottom>
		</el-col>
    <el-col :span="12">
			<div class="m_xation mb16">
				<div class="swiper-container statistics">
					<div class="swiper-wrapper">
						<div class="swiper-slide" :class="index == navIdx ? 'on' : ''" v-for="(item, index) in navList" :key="index" @click="navFun(index)">
							<div class="line" v-if="index != 0"></div>
							<div class="label">{{ item.label }}</div>
							<div class="num">
								<countTo :startVal="0" :endVal="item.num" :duration="CountAnimationDuration"></countTo>
							</div>
						</div>
					</div>
					<div class="swiper-pagination"></div>
			</div>
			</div>
			<MapData :mapType="5" :height="663" :mapindex="0"></MapData>
			<CenterBottom></CenterBottom>
		</el-col>
    <el-col :span="6">
			<RightTop></RightTop>
			<RightBottom></RightBottom>
		</el-col>
	</el-row>
</template>
<script>
import { mapState } from 'vuex';
import '@/assets/icon-font/ABDUCTION2000.css';
import 'swiper/swiper-bundle.css'
import Swiper from 'swiper/swiper-bundle'
import countTo from 'vue-count-to';
import Title from '../components/Title.vue'
import { random } from '@/utils'
import {barChart} from '../common'
import LeftCenter from './LeftCenter.vue';
import LeftBottom from './LeftBottom.vue';
import CenterBottom from './CenterBottom.vue';
import RightTop from './RightTop.vue';
import RightBottom from './RightBottom.vue';
import MapData from '@/views/homePage/overview/mapData.vue';
import { CountAnimationDuration, randomByLevel } from '@/views/homePage/common';
import TotalAnalysis from '../components/TotalAnalysis.vue'
import BaseEchart from '@/components/BaseEchart';

export default {
  components: { MapData, LeftCenter, LeftBottom, CenterBottom, RightTop, RightBottom, countTo, Title, TotalAnalysis, BaseEchart },
  data() {
    return {
      navList: [
        { label: '居民健康档案管理', num: 2068 },
        { label: '儿童健康管理', num: 5120 },
        { label: '孕产妇健康管理', num: 2230 },
        { label: '老年人健康管理', num: 8522 },
        { label: '慢性病患者管理', num: 9636 },
        { label: '严重精神患者管理', num: 8520 },
        { label: '肺结核患者管理', num: 4523 },
        { label: '中医药健康管理', num: 3201 },
        { label: '传染病及突发事件', num: 7410 },
        { label: '卫生计生监督协管', num: 8520 },
        { label: '免费提供避孕药具', num: 9630 },
        { label: '健康素养促进行动', num: 8563 },
				{ label: '健康教育', num: 8068 },
        { label: '预防接种', num: 1203 },
      ],
			navIdx: 0,
			CountAnimationDuration,
			option: undefined,
      analysisNum: 2068,
    }
  },
	computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    valueByLevel(){
      return this.analysisNum / (this.mapLevel + 1)
    }
  },
  watch: {
    mapDataArr: {
      handler() {
        const dataX = this.mapDataArr.map((d) => {
          return { name: d.regionName, value: randomByLevel({level: this.mapLevel}) };
        })
        this.option = barChart({ xData: dataX.map((d) => d.name), yData: dataX.map((d) => d.value) });
      }
    }
  },
	mounted(){
		new Swiper('.swiper-container', {
			pagination: '.swiper-pagination',
			slidesPerView: 5.5,
			paginationClickable: true,
			spaceBetween: 5
    });
	},
	methods: {
		init() {
      let data = this.mapDataArr.map((d) => {
				return { name: d.regionName, value: randomByLevel({level: this.mapLevel}) };
			})
			this.option = barChart({ xData: data.map((d) => d.name), yData: data.map((d) => d.value) });
    },
		navFun(i) {
			this.navIdx = i
			this.analysisNum = this.navList[i].num
			this.init();
		},
	}
}
</script>
<style lang="scss" scoped>
.m_xhealth{
	background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.m_xation{
	height: 100px;
	overflow: hidden;
}
.swiper-container {
	width: 100%;
	height: 100%;
}
.swiper-slide {
	text-align: center;
	padding: 0 10px;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	flex-direction: column;
	// align-items: center;
	position: relative;
	.label {
		font-size: 16px;
		color:#fff;
	}
	.num {
		color: #0bf9fe;
		font-weight: bold;
		font-style: italic;
		font-size: 26px;
	}
	.line {
		width: 1px;
		height: 29px;
		border: 1px solid #274574;
		position: absolute;
		left: -5px;
		top: 30px;
	}
}

.statistics {
	background: url('../../../assets/imgs/totalBgTop.png') no-repeat center;
	background-size: 100% 100%;
	height: 100px;
	padding: 0 20px;
	.on{
		border: 1px solid #0BEFFE;
	}
}
</style>