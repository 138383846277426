<template>
	<div class="container">
		<div class="bg"></div>
		<div class="progress" :style="{ transition: `all ${CountAnimationDuration / 2}ms linear`, background: color, width: `${innerPercent * 100}%` }"></div>
	</div>
</template>
<script>
import { CountAnimationDuration } from '@/views/homePage/common';
export default {
	props: ['percent', 'color'],
	data() {
		return {
      CountAnimationDuration,
			innerPercent: 0,
		};
	},
	watch: {
		percent: {
			handler() {
				setTimeout(() => {
					this.innerPercent = this.percent;
				}, 100);
			},
			immediate: true,
		},
	},
};
</script>
<style lang="scss" scoped>
.container {
	position: relative;
	width: 100%;
	height: 10px;
}
.bg {
	width: 100%;
	background-color: #16386f;
	height: 100%;
}
.progress {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
}
</style>
