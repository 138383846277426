<template>
  <div class="w100 mt16 bordered">
    <div class="title w100">
      <div class="titleWrapper">
        <div class="indicator"></div>
        公共突发事件
      </div>
      <div class="numContainer">
        <div class="numBoard mr16" v-for="item in numBoardateByLevel">
          <div>{{ item.title }}<span :class="item.class">{{ item.value }}</span></div>
        </div>
      </div>
    </div>
    <BaseEchart :option="option" class="mt8" style=" width: 100%; height: 123px"></BaseEchart>
  </div>
</template>
<script>
import BaseEchart from '@/components/BaseEchart';
import { mapState } from 'vuex';


export default {
  components: { BaseEchart },
  data() {
    return {
      option: null,
      numBoardate: [
        {
          title: '总数',
          value: 2065,
          class: 'blue'
        },
        {
          title: '特别重大',
          value: 340,
          class: 'red'
        },
        {
          title: '重大',
          value: 260,
          class: 'red'
        },
        {
          title: '较大',
          value: 120,
          class: 'red'
        },
        {
          title: '一般',
          value: 200,
          class: 'blue'
        },
        {
          title: '未分级',
          value: 655,
          class: 'blue'
        },
        {
          title: '非突发事件',
          value: 490,
          class: 'blue'
        },
      ]
    };
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    numBoardateByLevel() {
      return this.numBoardate.map(item => {
        return {
          ...item,
          value: Math.round(item.value / (this.mapLevel + 1))
        }
      })
    }
  },
  watch: {
    mapLevel: {
      immediate: true,
      handler: function () {
        this.option = ({
          axisLabel: {
            color: '#fff',
            fontSize: 10
          },
          grid: {
            left: 40,
            right: 40,
            top: 8,
            bottom: 32
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.2,
                type: [5, 5],
                dashOffset: 0,
                shadowBlur: 0
              },
            },
            data: ['传染病', '食物中毒', '职业中毒', '其它中毒事件', '环境卫生', '免疫接种', '群体性不明原因', '医疗机构内感染', '放射性卫生'],
            axisLabel: {
              interval: 0, // 强制显示所有标签
            }
          },
          yAxis: {
            type: 'value',
            splitNumber: 4,
            min: 0,
            max: 280,
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.2,
                type: [5, 5],
                dashOffset: 0,
                shadowBlur: 0
              },
            },
          },
          series: [
            {
              data: [90, 100, 200, 100, 110, 280, 150, 260, 160].map(d => {
                return Math.round(d / (this.mapLevel + 1))
              }),
              type: 'line',
              smooth: true,
              symbol: "none",
              lineStyle: {
                color: '#3BABF0',
              },
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: '#0D3155' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(0, 124, 190, 0.1)' // 100% 处的颜色
                  }],
                }
              }
            }
          ]
        })
      }
    },
  },
  mounted() {
    this.option = {
      axisLabel: {
        color: '#fff',
        fontSize: 10,
      },
      grid: {
        left: 40,
        right: 40,
        top: 8,
        bottom: 32,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.2,
            type: [5, 5],
            dashOffset: 0,
            shadowBlur: 0,
          },
        },
        data: ['传染病', '食物中毒', '职业中毒', '其它中毒事件', '环境卫生', '免疫接种', '群体性不明原因', '医疗机构内感染', '放射性卫生'],
        axisLabel: {
          interval: 0, // 强制显示所有标签
        }
      },
      yAxis: {
        type: 'value',
        splitNumber: 4,
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.2,
            type: [5, 5],
            dashOffset: 0,
            shadowBlur: 0,
          },
        },
      },
      series: [
        {
          data: [90, 100, 200, 100, 110, 280, 150, 260, 160],
          type: 'line',
          smooth: true,
          symbol: 'none',
          lineStyle: {
            color: '#3BABF0',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#0D3155', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(0, 124, 190, 0.1)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.title {
  line-height: 32px;
  padding-left: 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleWrapper {
    display: flex;
    align-items: center;
  }
  .numContainer {
    display: flex;
  }

  .numBoard {
    display: flex;
    gap: 24px;
    font-size: 14px;

    .blue {
      font-weight: bold;
      margin-left: 6px;
      font-size: 16px;
      color: $main-text;
    }

    .red {
      font-weight: bold;
      margin-left: 6px;
      font-size: 16px;
      color: #FF0000;
    }
  }

  .indicator {
    display: inline-block;
    height: 16px;
    width: 4px;
    margin-right: 8px;
    background-image: linear-gradient(#308fcc, #224abe);
  }
}</style>
